<template>
  <div>
    <display-token-dialog v-model="subdialog" :token="token" />
    <Dialog v-model="dialog" title="Create an access token">
      <template v-slot:activator="{ on }">
        <default-button
          color="success"
          :on="on"
          icon="fa-plus"
          id="create-access-token-btn"
          >Create access token</default-button
        >
      </template>
      <v-snackbar color="error" v-model="error">{{ errorMessage }}</v-snackbar>
      <v-card-text>
        <v-form v-model="valid">
          <service-account-form v-model="form" />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <default-button
          color="success"
          :disabled="!valid"
          @click="createToken"
          id="create-button"
          :loading="loading"
          icon="fa-plus"
        >
          Create token
        </default-button>
      </v-card-actions>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "@/components/dialogs/Dialog";
import ServiceAccountForm from "@/components/forms/ServiceAccountForm";
import DisplayTokenDialog from "@/components/dialogs/DisplayTokenDialog";
import DefaultButton from "@/components/widgets/DefaultButton";

import { mutations } from "@/gql";
const { createServiceAccount: mutation } = mutations;

export default {
  components: { DisplayTokenDialog, ServiceAccountForm, Dialog, DefaultButton },
  data: () => ({
    valid: null,
    dialog: false,
    form: {
      username: null,
      groupIds: [],
    },
    loading: false,
    error: false,
    errorMessage: null,
    subdialog: false,
    token: null,
  }),
  methods: {
    createToken() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation,
          variables: this.form,
        })
        .then(
          ({
            data: {
              createServiceAccount: { token },
            },
          }) => {
            this.token = token;
            this.$emit("create");
            this.loading = false;
            this.dialog = false;
            this.subdialog = true;
          },
        )
        .catch((error) => {
          this.error = true;
          this.errorMessage = error.message;
          this.loading = false;
        });
    },
  },
};
</script>
