<template>
  <Dialog v-model="dialog" :max-width="600" title="Delete access token">
    <v-snackbar color="error" v-model="error">{{ errorMessage }}</v-snackbar>
    <template v-slot:activator="{ on }">
      <default-button :on="on" color="error" icon="fa-trash"
        >Delete</default-button
      >
    </template>
    <v-card-text>
      You are about to delete the access token called
      {{ serviceAccount.username }}. This action cannot be undone. Are you sure
      you want to proceed?
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="error" text :loading="loading" @click="deleteServiceAccount"
        ><v-icon small class="pr-2">fa-trash</v-icon>Delete</v-btn
      >
    </v-card-actions>
  </Dialog>
</template>

<script>
import Dialog from "@/components/dialogs/Dialog";
import DefaultButton from "@/components/widgets/DefaultButton";

import { mutations } from "@/gql";

const { deleteServiceAccount: mutation } = mutations;

export default {
  components: { Dialog, DefaultButton },
  props: ["serviceAccount"],
  data: () => ({
    dialog: false,
    loading: false,
    error: false,
    errorMessage: null,
  }),
  methods: {
    deleteServiceAccount() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation,
          variables: {
            username: this.serviceAccount.username,
          },
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage = error.message.replace("GraphQL error: ", "");
          this.loading = false;
        })
        .then((result) => {
          if (result) {
            this.loading = false;
            this.$emit("delete");
            this.dialog = false;
          }
        });
    },
  },
};
</script>
