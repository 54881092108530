<template>
  <Dialog v-model="dialog" title="Your access token">
    <v-card-text
      >Your access token is shown below. Please keep it safe - it will not be
      displayed again.</v-card-text
    >
    <v-card-text>
      <div>
        <pre class="text-center title">
            <code data-test="token">{{ token }}</code>
          </pre>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn v-clipboard="token" text color="success" data-test="copy-button">
        <v-icon class="pr-2" small>fa-copy</v-icon>Copy to clipboard
      </v-btn>
      <v-spacer />
      <v-btn text color="primary" @click="closeDialog" data-test="close-button"
        >Close</v-btn
      >
    </v-card-actions>
  </Dialog>
</template>

<script>
import Dialog from "@/components/dialogs/Dialog.vue";
export default {
  props: ["value", "token"],
  components: { Dialog },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
