<template>
  <v-data-table
    dense
    :headers="headers"
    :items="items"
    item-key="name"
    class="elevation-0"
  >
    <template v-slot:item.groups="{ item }">
      <template v-for="(group, i) in item.groups">
        <v-chip small label v-if="i < 2" :key="group.key" class="mx-1">
          {{ group.name }}
        </v-chip>
      </template>
      <span class="grey--text" v-if="item.groups.length > 2">
        + {{ item.groups.length - 2 }} more</span
      >
    </template>
    <template v-slot:item.actions="{ item }">
      <v-card-actions class="px-0 ma-0 ml-n3">
        <delete-service-account
          @delete="$emit('refresh')"
          :service-account="item"
        />
        <rotate-keys-dialog
          @rotate="$emit('refresh')"
          :service-account="item"
        />
      </v-card-actions>
    </template>
  </v-data-table>
</template>

<script>
import { fileSize, niceDate } from "@/filters";
import DeleteServiceAccount from "@/components/dialogs/DeleteServiceAccount";
import RotateKeysDialog from "@/components/dialogs/RotateKeysDialog";

export default {
  components: { RotateKeysDialog, DeleteServiceAccount },
  props: ["items"],
  filters: { fileSize, niceDate },
  data: () => ({
    headers: [
      {
        text: "Name",
        value: "username",
      },
      {
        text: "Roles",
        value: "groups",
      },
      {
        text: "Actions",
        value: "actions",
      },
    ],
  }),
};
</script>
