<template>
  <div>
    <Dialog v-model="dialog" :max-width="600" title="Rotate keys">
      <v-snackbar color="error" v-model="error">{{ errorMessage }}</v-snackbar>
      <template v-slot:activator="{ on }">
        <default-button color="info" :on="on" icon="fa-sync"
          >Rotate</default-button
        >
      </template>
      <v-card-text>
        You are about to refresh the access token called
        {{ serviceAccount.username }}. This action cannot be undone, and your
        existing access key will cease to work. Are you sure you want to
        proceed?
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="error" text :loading="loading" @click="rotateKeys"
          ><v-icon small class="pr-2">fa-sync</v-icon>Rotate</v-btn
        >
      </v-card-actions>
    </Dialog>
    <display-token-dialog v-model="subdialog" :token="token" />
  </div>
</template>

<script>
import { mutations } from "@/gql";

const { rotateKeys: mutation } = mutations;
import Dialog from "@/components/dialogs/Dialog";
import DisplayTokenDialog from "@/components/dialogs/DisplayTokenDialog";
import DefaultButton from "@/components/widgets/DefaultButton";
export default {
  components: { DefaultButton, DisplayTokenDialog, Dialog },
  props: ["serviceAccount"],
  data: () => ({
    dialog: false,
    subDialog: false,
    loading: false,
    error: false,
    errorMessage: null,
    subdialog: false,
    token: null,
  }),
  methods: {
    rotateKeys() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation,
          variables: {
            username: this.serviceAccount.username,
          },
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage = error.message.replace("GraphQL error: ", "");
          this.loading = false;
        })
        .then((result) => {
          if (result) {
            const {
              data: {
                rotateKeys: { token },
              },
            } = result;
            this.loading = false;
            this.token = token;
            this.dialog = false;
            this.subdialog = true;
          }
        });
    },
  },
};
</script>
