<template>
  <v-row class="ma-4">
    <page-header />

    <v-col cols="12">
      <v-card tile>
        <table-header title="Access tokens">
          <create-access-token-dialog
            @create="$apollo.queries.serviceAccounts.refetch()"
          />
          <default-button
            icon="fa-sync"
            @click="$apollo.queries.serviceAccounts.refetch()"
            >Refresh</default-button
          >
        </table-header>
        <v-card-text>
          <service-accounts
            :items="serviceAccounts"
            @refresh="$apollo.queries.serviceAccounts.refetch()"
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { queries } from "@/gql";
import ServiceAccounts from "@/components/tables/ServiceAccounts";

const { serviceAccounts } = queries;
import CreateAccessTokenDialog from "@/components/dialogs/CreateAccessTokenDialog";
import PageHeader from "@/components/widgets/PageHeader";
import TableHeader from "@/components/widgets/TableHeader";
import DefaultButton from "@/components/widgets/DefaultButton";
export default {
  components: {
    DefaultButton,
    TableHeader,
    PageHeader,
    CreateAccessTokenDialog,
    ServiceAccounts,
  },
  apollo: { serviceAccounts },
};
</script>
