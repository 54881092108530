<template>
  <v-row>
    <v-col cols="2" class="subtitle-1 mt-2">Token name</v-col>
    <v-col cols="10">
      <v-text-field
        v-model="form.username"
        dense
        id="username-field"
        outlined
        :rules="[rules.required]"
      />
    </v-col>
    <v-col cols="2" class="subtitle-1 mt-2">Allowed roles</v-col>
    <v-col cols="10">
      <v-autocomplete
        multiple
        v-model="form.groupIds"
        :items="groups"
        dense
        id="group-select"
        chips
        small-chips
        :rules="[rules.minOne]"
        clearable
        item-text="name"
        item-value="id"
        outlined
      ></v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import { queries } from "@/gql";

const { groups } = queries;

export default {
  props: ["value"],
  apollo: { groups },
  computed: {
    form: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
  },
  data: () => ({
    rules: {
      required: (v) => !!v || "This field is required",
      minOne: (v) => (v && v.length > 0) || "This field is required",
    },
  }),
};
</script>
